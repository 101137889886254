<template>
	<div>
		<el-dialog
			:title="titleDialog"
			:visible="showDialog"
			@close="close"
			@open="create"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			append-to-body
			width="30%"
		>
			<form autocomplete="off" @submit.prevent="submit">
				<v-row>
					<v-col cols="12" xl="12" lg="12" md="12" sm="12">
						<div class="form-group" :class="{ danger: errors.name }">
							<label>Nombre</label>
							<el-input v-model="form.name"></el-input>
							<small
								class="error--text"
								v-if="errors.name"
								v-text="errors.name[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="12" lg="12" md="12" sm="12">
						<div class="form-group" :class="{ danger: errors.name }">
							<label>Código</label>
							<el-input v-model="form.code" readonly dusk="code">
								<el-button
									type="primary"
									slot="append"
									:loading="loadingGetCode"
									@click.prevent="getCode"
									>Obtener</el-button
								>
							</el-input>
							<small
								class="error--text"
								v-if="errors.name"
								v-text="errors.name[0]"
							></small>
						</div>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" xl="12" lg="12" md="12" sm="12">
						<el-button type="danger" @click.prevent="close()">Cancelar</el-button>
						<el-button type="primary" native-type="submit" :loading="loading">
							<template v-if="loading">
								Guardando...
							</template>
							<template v-else>
								Guardar
							</template>
						</el-button>
					</v-col>
				</v-row>
			</form>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: ['showDialog', 'recordId'],
	data() {
		return {
			loading: false,
			loadingGetCode: false,
			titleDialog: null,
			resource: 'waiters',
			valid: false,
			errors: {},
			form: {},
			rules: {
				name: [{ required: true, message: 'El nombre es requerido', trigger: 'blur' }],
			},
		};
	},
	created() {},
	methods: {
		initForm() {
			this.errors = {};
			this.form = {
				id: null,
				name: null,
				code: null,
			};
		},
		create() {
			this.titleDialog = this.recordId ? 'Editar mozo' : 'Nuevo mozo';
			if (this.recordId) {
				this.$http.get(`/${this.resource}/record/${this.recordId}`).then((response) => {
					this.form = response.data.data;
				});
			}
		},
		getCode() {
			this.loadingGetCode = true;
			this.$http.get(`/${this.resource}/get-code`).then((response) => {
				this.form.code = response.data;

				this.loadingGetCode = false;
			});
		},
		submit() {
			this.loading = true;
			this.$http
				.post(`/${this.resource}`, this.form)
				.then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
						this.$eventHub.$emit('reloadData', this.resource);
						this.close();
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					if (error.response.status === 422) {
						this.errors = error.response.data.errors;
						console.log(this.errors)
					} else {
						this.$message.error(error.response.data.message);
					}
				})
				.then(() => {
					this.loading = false;
				});
		},
		close() {
			this.$emit('update:showDialog', false);
			this.initForm();
		},
	},
};
</script>
